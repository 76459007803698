/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for modern effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #f9f9f9; /* Softer off-white color */
  padding: 30px;
  border-radius: 12px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* Soft shadow */
  position: relative;
  animation: slide-in 0.3s ease-out; /* Smooth slide-in effect */
}

/* Modal slide-in animation */
@keyframes slide-in {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Modal header with close button */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.close-modal-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.close-modal-button:hover {
  color: #000;
}

/* Modal body text */
.modal-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
}

.modal-body h3 {
  color: #555;
  margin: 20px 0 10px;
  font-size: 1.2rem;
  text-align: left;
}

.modal-body p, .modal-body ul {
  margin-bottom: 10px;
  text-align: left;
}

/* Custom info list with square bullets, left-aligned */
.info-list {
  list-style-type: square;
  padding-left: 20; /* Remove extra left padding */
  margin: 15px 0;
  text-align: left; /* Ensure left alignment */
}

.info-list li {
  margin: 5px 0;
  padding-left: 1.5em; /* Add padding to align text with the squares */
  position: relative;
}




/* Data policy link */
.data-policy-link {
  color: #000000; /* Modern blue color */
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.data-policy-link:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline;
}

/* Close button on hover */
.data-policy-button:focus, .close-modal-button:focus {
  outline: none;
}
