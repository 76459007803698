.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.nav-link {
  color: #353535;
}

.nav-link:hover {
  color: gray;
}


.navbar .router-link-exact-active {
  color:#041433;
  border-bottom:3px solid #212121
 }

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/*Added from GPT */

.app-container {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f4f4f4;
  text-align: center;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  background-color: #333;
  color: white;
  padding: 60px 20px;
}

.hero-section h1 {
  font-size: 2.5em;
  color: #00bfff; /* Adds a pop of color */
}

.hero-section p {
  font-size: 1.2em;
}

.cta-button {
  background-color: #00bfff; /* Bright color for contrast */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #007acc; /* Darker shade on hover */
}

/* Info Section */
.info-section {
  background-color: #f4f4f4;
  color: #333;
  padding: 40px 20px;
}

.info-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.info-section p {
  font-size: 1.1em;
  max-width: 800px;
  margin: 0 auto;
}

/* Testimonials Section */
.testimonials-section {
  background-color: #f4f4f4;
  color: #333;
  padding: 40px 20px;
}

.testimonials-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.testimonials blockquote {
  font-size: 1.1em;
  color: #555;
  margin: 10px auto;
  max-width: 600px;
  font-style: italic;
}

/* Google Reviews Section */
.reviews-section {
  background-color: #f4f4f4;
  color: #333;
  padding: 40px 20px;
}

.reviews-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.google-reviews p {
  font-size: 1.1em;
  color: #555;
}

/* Car Images Section */
.car-images {
  padding: 40px 0;
}

.responsive-car-image {
  width: 100%;
  height: auto;
  max-width: 1200px;
}

/* Contact Form Section */
.form-section {
  padding: 40px 20px;
  background-color: #f4f4f4;
}

.appraisal-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.appraisal-form div {
  margin-bottom: 15px;
}

.appraisal-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.appraisal-form input, .appraisal-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #007acc;
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  margin-top: 20px;
}

.hero-section {
  position: relative;
  text-align: center;
  background-color: #333;
  color: white;
  padding: 60px 20px;
}

.hero-content {
  z-index: 1;
  position: relative;
}

.cta-button {
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  margin-top: 20px;
}

.sharp-image {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin-top: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Adds a sharp, professional look */
}

@media (max-width: 768px) {
  .sharp-image {
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
}

/* Main container */
.app-container {
  text-align: center;
  padding: 20px;
}

.hero-section {
  padding: 60px 20px;
  background-color: #333;
  color: white;
}

.cta-button {
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

/* Form container */
.form-container {
  position: relative;
  background-color: #f0f0f0;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Form header */
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-header h2 {
  margin: 0;
}

/* Close button */
.close-button {
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

/* Form styling */
.appraisal-form label {
  display: block;
  margin-bottom: 5px;
}

.appraisal-form input,
.appraisal-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.submit-button {
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

a.phone-link {
  color: #00bcd4; /* Choose a color that matches your theme */
  font-weight: bold;
  text-decoration: none;
}

a.phone-link:hover {
  text-decoration: underline;
}

