/* Data Policy Container */
.data-policy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .data-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .data-policy-container p {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .data-policy-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
    text-align: left;
  }
  
  .data-policy-container .info-list {
    list-style-type: square;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .data-policy-container .info-list li {
    margin-bottom: 5px;
  }
  